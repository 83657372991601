
import Modal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { format as formatCNPJ } from "@/utils/cnpj";
import formatDate from "@/utils/formatDate";
import DebtAgreementService, {
  CreateDebtAgreementDto,
  LoanInstallmentWithDaysLate,
  LoanWithDaysLate,
  UpdateDebtAgreementDto
} from "../../services/debt-agreement-service";
import { DebtAgreement } from "../../types/debt-agreement";
import formatCurrency from "../../utils/formatCurrency";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";
import AlertBox from "@/components/AlertBox.vue";
import DatePicker from "@/components/DatePicker.vue";
import dayjs from "dayjs";
import BorrowerService from "../../services/borrower-service";
import Borrower from "../../types/borrower";
import SafetyService from "../../services/safety-service";
import User from "../../types/user";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    AlertBox,
    DatePicker
  },
  directives: { mask }
})
export default class DebtAgreementEditModalConditions extends Vue {
  @Prop() debtAgreement: DebtAgreement | undefined;
  @Prop() borrowerCpf!: string;

  formatCNPJ = formatCNPJ;
  formatDate = formatDate;
  formatCurrency = formatCurrency;

  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();
  borrowerService: BorrowerService = BorrowerService.getInstance();
  safetyService: SafetyService = SafetyService.getInstance();

  loadingCPFValidation: boolean = false;
  cpfValidated: boolean = false;
  loadingData: boolean = false;
  errorLoadingData: boolean = false;
  loadingUserEmailUpdate: boolean = false;
  userEmailAlreadyInSync: boolean = false;
  borrower: Borrower = {
    cpf: "",
    name: ""
  } as Borrower;
  user: User = {
    email: ""
  } as User;
  loadingEligibleLoans: boolean = false;
  EligibleLoansHeaders = [
    { text: "Contrato", value: "proposalNumber" },
    { text: "Proposta", value: "id" },
    { text: "Data proposta", value: "requestDate" },
    { text: "Valor solicitado", value: "requestedAmount" },
    { text: "Total Parcelas", value: "numInstallments" },
    { text: "Parcelas pendentes", value: "pendingInstallmentsCount" },
    { text: "Empresa - CNPJ", value: "company" },
    { text: "Dias Atraso", value: "daysLate" }
  ];
  EligibleLoanInstallmentsHeaders = [
    { text: "Contrato", value: "proposalNumber" },
    { text: "Proposta", value: "loanId" },
    { text: "Data vencimento", value: "dueDate" },
    { text: "Valor parcela", value: "value" },
    { text: "Número parcela", value: "number" },
    { text: "Status", value: "status" },
    { text: "Dias atraso", value: "daysLate" }
  ];
  eligibleLoans: LoanWithDaysLate[] = [];
  selectedLoans: LoanWithDaysLate[] = [];
  selectedLoanInstallments: LoanInstallmentWithDaysLate[] = [];
  discountOptions: Array<{ name: string; value: string }> = [
    { name: "Sem desconto", value: "ZERO" },
    { name: "Valor (R$)", value: "VALUE" },
    { name: "Porcentagem (%)", value: "PERCENTAGE" }
  ];
  selectedDiscountOption: string = "ZERO";
  downPaymentOptions: Array<{ name: string; value: string }> = [
    { name: "Sem entrada", value: "NO" },
    { name: "Com entrada", value: "YES" }
  ];
  selectedDownPaymentOption: string = "NO";
  discountedValueInBrl: number = 0;
  discountedValueInPercent: number = 0;
  minFirstInstallmentDueDate = dayjs().add(1, "day").format("YYYY-MM-DD");
  form: CreateDebtAgreementDto = {
    loanId: 0,
    loanInstallmentIds: [],
    negotiatedValue: 0,
    downPaymentValue: 0,
    numInstallments: 1,
    firstInstallmentDueDate: dayjs().add(5, "days").format("YYYY-MM-DD")
  } as CreateDebtAgreementDto;
  loadingSaveDebtAgreement: boolean = false;

  async mounted(): Promise<void> {
    try {
      this.loadingData = true;

      if (this.debtAgreement?.id) {
        this.borrower.cpf = this.debtAgreement.borrowerCpf!;

        await this.fetchDataFromCpf();

        const [error, debtAgreement] =
          await this.debtAgreementService.findDebtAgreementById(
            this.debtAgreement.id
          );

        if (error) {
          this.errorLoadingData = true;
          this.loadingData = false;
          this.$notify({
            title: "Erro",
            text: getErrorMessageFromApiError(error),
            type: "error"
          });
          return;
        }

        this.selectedLoans = [
          this.eligibleLoans.find((l) => l.id === debtAgreement!.loanId)!
        ];
        this.selectedLoanInstallments =
          this.selectedLoans
            .at(0)
            ?.installments.filter((li) =>
              debtAgreement!.loanInstallments.some(
                (dai) => dai.loanInstallmentId === li.id
              )
            ) || [];
        this.selectedDiscountOption = debtAgreement!.discountedValue
          ? "VALUE"
          : "ZERO";
        this.discountedValueInBrl = debtAgreement!.discountedValue || 0;
        this.selectedDownPaymentOption = debtAgreement!.downPaymentValue
          ? "YES"
          : "NO";
        this.form = {
          loanId: debtAgreement!.loanId!,
          loanInstallmentIds: debtAgreement!.loanInstallments.map((i) => i.id),
          negotiatedValue: debtAgreement!.negotiatedValue!,
          downPaymentValue: debtAgreement!.downPaymentValue!,
          numInstallments: debtAgreement!.numInstallments!,
          firstInstallmentDueDate: dayjs(
            debtAgreement!.firstInstallmentDueDate!
          ).format("YYYY-MM-DD")
        };
      } else {
        this.borrower.cpf = this.borrowerCpf;

        await this.fetchDataFromCpf();
      }

      this.loadingData = false;
    } catch (error) {
      this.errorLoadingData = true;
      this.loadingData = false;
    }
  }

  async fetchDataFromCpf(): Promise<void> {
    await Promise.all([
      this.loadBorrowerAndUserData(),
      this.listEligibleLoans()
    ]);
  }

  async loadBorrowerAndUserData(): Promise<void> {
    const cpf = this.borrower.cpf!.replace(/\D/g, "");
    if (cpf.length < 11) {
      return;
    }

    const [borrowerError, borrowerResponse] =
      await this.borrowerService.getBorrowerByCpf(cpf);

    if (borrowerError) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(borrowerError),
        type: "error"
      });
      throw borrowerError;
    }

    this.borrower = borrowerResponse!;

    const [userError, userResponse] = await this.safetyService.listUsers({
      page: 1,
      limit: 1,
      userIds: [this.borrower.userId]
    });

    if (userError) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(userError),
        type: "error"
      });
      throw userError;
    }

    this.user = userResponse!.items[0];
  }

  async listEligibleLoans(): Promise<void> {
    this.cpfValidated = false;

    const cpf = this.borrower.cpf!.replace(/\D/g, "");
    if (cpf.length < 11) {
      return;
    }

    this.loadingCPFValidation = true;
    this.loadingEligibleLoans = true;
    const [error, response] =
      await this.debtAgreementService.consultEligibleLoansForCpf(cpf);
    this.loadingEligibleLoans = false;
    this.loadingCPFValidation = false;

    if (error) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      throw error;
    }

    if (response) {
      this.cpfValidated = true;
      this.eligibleLoans = response;
    }
  }

  async updateUserEmail(): Promise<void> {
    this.loadingUserEmailUpdate = true;

    const [error] = await this.safetyService.updateUser(this.user.id!, {
      email: this.user.email
    });

    if (error) {
      this.loadingUserEmailUpdate = false;
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    this.$notify({
      text: "E-mail atualizado com sucesso",
      type: "success"
    });

    this.loadingUserEmailUpdate = false;
    this.userEmailAlreadyInSync = true;
  }

  async save(): Promise<void> {
    this.loadingSaveDebtAgreement = true;

    let error, response;

    if (!this.debtAgreement?.id) {
      [error, response] = await this.debtAgreementService.createDebtAgreement(
        this.form as CreateDebtAgreementDto
      );
    } else {
      [error, response] = await this.debtAgreementService.updateDebtAgreement(
        this.debtAgreement.id,
        this.form as UpdateDebtAgreementDto
      );
    }

    if (error) {
      this.$notify({
        title: "Acordo",
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.$notify({
        title: "Acordo",
        type: "success",
        text: "Acordo salvo com sucesso"
      });

      this.$emit("next", response);
    }

    this.loadingSaveDebtAgreement = false;
  }

  @Watch("selectedLoans")
  _selectedLoan(): void {
    this.form.loanId = this.selectedLoans.at(0)?.id || 0;
    this.selectedLoanInstallments = [];
    this.recalculateNegotiatedValue();
  }

  @Watch("selectedLoanInstallments")
  _selectedLoanInstallments(): void {
    this.form.loanInstallmentIds = this.selectedLoanInstallments.map(
      (i) => i.id
    );
    this.recalculateNegotiatedValue();
  }

  @Watch("discountedValueInBrl")
  _discountedValueInBrl(): void {
    this.recalculateNegotiatedValue();
  }

  @Watch("discountedValueInPercent")
  _discountedValueInPercent(): void {
    this.recalculateNegotiatedValue();
  }

  recalculateNegotiatedValue(): void {
    this.form.negotiatedValue = this.roundRegular(
      this.originalValue - this.discountedValue
    );
  }

  get selectedLoan(): LoanWithDaysLate | undefined {
    return this.selectedLoans.at(0);
  }

  /** Sum of selected loan installments */
  get originalValue(): number {
    return this.roundRegular(
      this.selectedLoanInstallments
        .map((i) => Number(i.value))
        .reduce((acc, curr) => acc + curr, 0)
    );
  }

  get maxDiscountedValue(): number {
    // Discount value should be rounded down, otherwise it could slightly
    // surpass the limit of 90% of original value
    return this.roundDown(this.originalValue * 0.9);
  }

  get discountedValue(): number {
    if (this.selectedDiscountOption === "ZERO") {
      return 0;
    } else if (this.selectedDiscountOption === "VALUE") {
      return this.discountedValueInBrl;
    } else if (this.selectedDiscountOption === "PERCENTAGE") {
      // Discount value should be rounded down, otherwise it could slightly
      // surpass the limit of 90% of original value
      return this.roundDown(
        (this.discountedValueInPercent / 100) * this.originalValue
      );
    }

    return 0;
  }

  get installmentValue(): number {
    return this.roundRegular(
      (this.form.negotiatedValue - (this.form.downPaymentValue || 0)) /
        this.form.numInstallments
    );
  }

  get anyLoading(): boolean {
    return (
      this.loadingCPFValidation ||
      this.loadingEligibleLoans ||
      this.loadingSaveDebtAgreement
    );
  }

  close(): void {
    this.$emit("close");
  }

  roundRegular(value: number): number {
    return Number(value.toFixed(2));
  }

  roundDown(value: number): number {
    return Math.floor(value * 100) / 100;
  }
}
