
import DatePicker from "@/components/DatePicker.vue";
import Modal from "@/components/Modal.vue";
import BorrowerService from "@/services/borrower-service";
import CompanyService from "@/services/company-service";
import LoanService from "@/services/loan-service";
import store from "@/store";
import Batch from "@/types/batch";
import Borrower from "@/types/borrower";
import Company from "@/types/company";
import Loan from "@/types/loan";
import LoanInstallment from "@/types/loan-installment";
import PayerType, { PayerTypeEnum } from "@/types/payer-type";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import dayjs, { Dayjs } from "dayjs";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { ProviderInstance } from "vee-validate/dist/types/types";
import { Component, Vue } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import formatCurrency from "@/utils/formatCurrency";
import { format as formatCPF } from "@/utils/cpf";
import { format as formatCNPJ } from "@/utils/cnpj";
import PaymentOrigin, { PaymentOriginEnum } from "@/types/payment-origin";
import PaymentService from "@/services/payment-service";
import { LoanInstallmentStatusEnum } from "@/types/loan-installment-status";
import { ManuallyGenerateBankSlipForCompanyDTO } from "@/services/payment-service/types/manually-generate-bank-slip-for-company";
import { ManuallyGenerateBankSlipForBorrowerDTO } from "@/services/payment-service/types/manually-generate-bank-slip-for-borrower";
import { Watch } from "vue-property-decorator";
import AlertBox from "@/components/AlertBox.vue";
import cloneDeep from "lodash/cloneDeep";
import { ManuallyGenerateIndividualBanksSlipsForBorrowerDTO } from "@/services/payment-service/types/manually-generate-individual-banks-slips-for-borrower";
import { removeNonDigits } from "@/utils/removeNonDigits";
import { ManuallyGenerateBankSlipDTO } from "@/services/payment-service/types/manually-generate-bank-slip";

interface Form {
  payerTypeId: number | null;
  entityId: number | null;
  companyId: number | null;
  loanId: number | null;
  batchId: number | null;
  paymentOriginId: number | null;
  borrowerId: number | null;
  borrowerCpf: string | null;
  loanInstallments: LoanInstallment[];
  loans: Loan[];
  amount: number;
  dueDate: string;
  observation: string;
  isUniqueBankSlip: boolean;
  updateInstallment: boolean;
  segmentName: string | null;
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    DatePicker,
    AlertBox
  },
  directives: { mask }
})
export default class ChargeManageModal extends Vue {
  nowDate = new Date();
  userType: string;
  loading = false;
  loadingCompanies = false;
  loadingBorrowers = false;
  loadingBorrowerLoans = false;
  loadingCompanyBatches = false;
  loadingPayerTypes = false;
  loadingBorrowerLoanInstallments = false;
  PayerTypeEnum = PayerTypeEnum;
  PaymentOriginEnum = PaymentOriginEnum;
  service: LoanService;
  companyService: CompanyService;
  borrowerService: BorrowerService;
  loanService: LoanService;
  paymentService: PaymentService;
  payerTypes: Partial<PayerType>[];
  payers: Borrower[] | Company[];
  borrowers: Borrower[];
  companies: Company[];
  companyBatches: Batch[];
  borrowerLoans: (Loan & { debitValue?: number })[];
  borrowerLoanInstallments: LoanInstallment[];
  borrowerLoanInstallmentsHeaders = [
    { text: "Proposta", value: "loanId" },
    { text: "Parcela", value: "number" },
    { text: "Valor", value: "value" },
    { text: "Valor presente", value: "presentValue" },
    { text: "Status", value: "status" }
  ];
  borrowerLoanInstallmentsHeadersForBorrowers = [
    { text: "Contrato", value: "proposalNumber" },
    { text: "Proposta", value: "loanId" },
    { text: "Data de vencimento", value: "dueDate" },
    { text: "Parcela", value: "number" },
    { text: "Status", value: "status" },
    { text: "Data de vencimento", value: "dueDateEdit" },
    { text: "Valor do boleto", value: "bankSlipValue" },
    { text: "Atualizar Parcela", value: "updateCheckbox" }
  ];
  LoanHeaders = [
    { text: "Proposta", value: "id" },
    { text: "Empresa Vinculada", value: "companyId" },
    { text: "Pagador", value: "borrowerId" },
    { text: "Valor do contrato", value: "total" },
    { text: "Total Parcelas", value: "numInstallments" },
    { text: "Total Parcelas Pendentes", value: "totalInstalmentsPendents" },
    { text: "Saldo Devedor", value: "debitValue" }
  ];
  BorrowersLoansHeaders = [
    { text: "Contrato", value: "proposalNumber" },
    { text: "Proposta", value: "id" },
    { text: "Data Proposta", value: "requestDate" },
    { text: "Valor Solicitado", value: "requestedAmount" },
    { text: "Total Parcelas", value: "numInstallments" },
    { text: "Total Parcelas Pendentes", value: "totalInstalmentsPendents" },
    { text: "Empresa - CNPJ", value: "companyId" }
  ];
  paymentOrigins: PaymentOrigin[];

  emptyForm = {
    payerTypeId: null,
    entityId: null,
    companyId: null,
    loanId: null,
    batchId: null,
    paymentOriginId: null,
    borrowerId: null,
    borrowerCpf: "",
    isUniqueBankSlip: false,
    updateInstallment: false,
    loanInstallments: [],
    loans: [],
    amount: 0,
    dueDate: "",
    observation: "",
    segmentName: ""
  };
  form: Form = cloneDeep(this.emptyForm);

  formatCPF = formatCPF;
  formatCurrency = formatCurrency;
  formatCNPJ = formatCNPJ;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.borrowerService = BorrowerService.getInstance();
    this.loanService = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();

    const authenticatedUser = store.getters["auth/authenticatedUser"];
    this.userType = authenticatedUser.type;
    this.payerTypes = [];
    this.payers = [];
    this.companies = [];
    this.borrowers = [];
    this.companyBatches = [];
    this.borrowerLoans = [];
    this.borrowerLoanInstallments = [];
    this.form.isUniqueBankSlip = false;
    this.form.updateInstallment = false;
    this.paymentOrigins = [
      {
        id: PaymentOriginEnum.SEPARATE_CHARGE.id,
        name: PaymentOriginEnum.SEPARATE_CHARGE.name,
        description: PaymentOriginEnum.SEPARATE_CHARGE.description
      },
      {
        id: PaymentOriginEnum.RESCISSION.id,
        name: PaymentOriginEnum.RESCISSION.name,
        description: PaymentOriginEnum.RESCISSION.description
      }
    ];
  }

  mounted() {
    this.fetchPayerTypes();
  }

  onPayerTypeChanged(): void {
    this.form = cloneDeep(this.emptyForm);
    const validationInstance = this.$refs.obs as ProviderInstance;
    this.$nextTick(() => validationInstance.reset());
  }

  async save() {
    this.loading = true;
    let error, payment;

    const { loanInstallments, ...rawForm } = this.form;
    const form: Omit<Form, "loanInstallments"> & {
      loanInstallmentIds: number[];
    } = {
      ...rawForm,
      loanInstallmentIds: loanInstallments.map(
        (installment: LoanInstallment) => installment.id
      )
    };
    if (!form.dueDate) form.dueDate = dayjs().format("YYYY-MM-DD");

    if (form.payerTypeId === PayerTypeEnum.EMPRESA) {
      [error, payment] =
        await this.paymentService.manuallyGenerateBankSlipForCompany(
          form as ManuallyGenerateBankSlipForCompanyDTO
        );
    } else if (form.payerTypeId === PayerTypeEnum.TOMADOR) {
      if (!form.isUniqueBankSlip) {
        const formForBorrower: ManuallyGenerateIndividualBanksSlipsForBorrowerDTO =
          {
            entityId: this.form.entityId,
            payerTypeId: this.form.payerTypeId,
            paymentOriginId: PaymentOriginEnum.BORROWER.id,
            isUniqueBankSlip: form.isUniqueBankSlip,
            bankSlipsData: loanInstallments.map(
              ({ id, value, dueDate, ...rawData }) => {
                return {
                  loanInstallmentId: id,
                  amount: rawData.bankSlipValue ?? parseFloat(value),
                  dueDate: rawData.dueDateEdit ?? dueDate
                };
              }
            )
          };

        [error, payment] =
          await this.paymentService.manuallyGenerateBankSlipForBorrower(
            formForBorrower
          );
      } else {
        form.paymentOriginId = PaymentOriginEnum.BORROWER.id;
        const { loans, ...formData } = form;
        [error, payment] = await this.paymentService.manuallyGenerateBankSlip(
          formData as ManuallyGenerateBankSlipDTO
        );
      }
    } else if (form.payerTypeId === PayerTypeEnum.LIQUIDAR_CONTRATO) {
      form.payerTypeId = PayerTypeEnum.TOMADOR;
      [error, payment] =
        await this.paymentService.manuallyGenerateBankSlipForSettleBorrowerContract(
          form as ManuallyGenerateBankSlipForBorrowerDTO
        );
    } else {
      this.$notify({
        type: "error",
        text: "Tipo de cobrança inválida"
      });
    }

    if (!error) {
      this.$notify({
        type: "success",
        text: "Cobrança inserida com sucesso"
      });
      this.$emit("input", payment);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
    this.loading = false;
  }

  handleContractSelection(
    selectedContracts: (Loan & { debitValue?: number })[]
  ) {
    if (!selectedContracts) this.form.observation = "";
    this.form.observation = "Contrato - Saldo Devedor\n";
    selectedContracts.forEach((contract) => {
      this.form.observation += `${contract.id} - ${formatCurrency(
        contract.debitValue ?? 0
      )}\n`;
    });
  }

  handleCompanyChange(entityId) {
    const selectedCompany = this.companies.find((item) => item.id === entityId);

    this.form.paymentOriginId = null;
    if (selectedCompany) {
      this.form.segmentName = selectedCompany.segmentName;
    } else {
      this.form.segmentName = "";
    }
  }

  async fetchPayerTypes(): Promise<Partial<PayerType>[]> {
    this.form.payerTypeId = null;
    this.loadingPayerTypes = true;
    this.payerTypes = [];
    const [error, payerTypes] =
      await this.paymentService.listPaymentPayerTypes();

    if (!error) {
      this.payerTypes = payerTypes!;
      this.payerTypes.push({
        id: PayerTypeEnum.LIQUIDAR_CONTRATO,
        name: "LIQUIDAR_CONTRATO",
        description: "Liquidar Contrato"
      });
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }

    this.loadingPayerTypes = false;

    return this.payerTypes;
  }

  async fetchBorrowers(): Promise<void> {
    let companyId, borrowerCpf;
    switch (this.form.payerTypeId) {
      case PayerTypeEnum.EMPRESA:
        companyId = this.form.entityId;
        break;
      case PayerTypeEnum.TOMADOR:
        borrowerCpf = removeNonDigits(this.form.borrowerCpf);
        this.form.entityId = null;
        break;
      case PayerTypeEnum.LIQUIDAR_CONTRATO:
        companyId = this.form.companyId;
        this.form.entityId = null;
        break;
    }
    if (!companyId && !borrowerCpf) return;

    this.loadingBorrowers = true;
    this.borrowers = [];
    if (companyId) {
      const [borrowersLoansError, borrowersLoansData] =
        await this.loanService.getLoanBorrowersByCompany(companyId);
      if (!borrowersLoansError) {
        this.borrowers = borrowersLoansData!;
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(borrowersLoansError)
        });
      }
    }
    if (borrowerCpf) {
      const [borrowersLoansError, borrowersLoansData] =
        await this.borrowerService.getLoanBorrowersByCpf(borrowerCpf);
      if (!borrowersLoansError) {
        this.borrowers.push(borrowersLoansData!);
        this.form.entityId = borrowersLoansData!.id;
        await this.fetchBorrowerLoans();
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(borrowersLoansError)
        });
      }
    }
    this.loadingBorrowers = false;
  }

  async fetchCompanies(): Promise<Company[]> {
    this.form.entityId = null;
    this.loadingCompanies = true;
    this.companies = [];

    const [error, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: -1,
      sort: "name:ASC",
      search: ""
    });

    if (!error) {
      this.companies = companies!.items;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingCompanies = false;

    return this.companies;
  }

  async fetchCompanyBatches(): Promise<void> {
    if (!this.form.entityId) return;

    this.form.batchId = null;
    this.loadingCompanyBatches = true;
    this.companyBatches = [];

    const [error, companyBatches] = await this.loanService.listBatches({
      page: 1,
      limit: -1,
      sort: "id:ASC",
      companyId: this.form.entityId
    });

    if (!error) {
      this.companyBatches = companyBatches!.items;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingCompanyBatches = false;
  }

  async fetchBorrowerLoans(): Promise<void> {
    if (!this.form.entityId) return;

    this.form.loanId = null;
    this.loadingBorrowerLoans = true;
    this.borrowerLoans = [];

    const [error, borrowerLoans] = await this.loanService.listLoans({
      page: 1,
      limit: -1,
      sort: "id:ASC",
      companyId: this.form.companyId,
      borrowerId: this.form.entityId,
      statusGroupId: 2,
      loadDocuments: false,
      loadStatusHistory: false,
      loadInstallments:
        (this.form.payerTypeId === PayerTypeEnum.LIQUIDAR_CONTRATO ||
          this.form.payerTypeId === PayerTypeEnum.TOMADOR) ??
        false
    });

    if (!error) {
      if (
        (this.form.payerTypeId === PayerTypeEnum.LIQUIDAR_CONTRATO ||
          this.form.payerTypeId === PayerTypeEnum.TOMADOR) &&
        borrowerLoans?.items.length
      ) {
        this.borrowerLoans = (
          await Promise.all(
            borrowerLoans.items.map(async ({ installments, ...loan }) => {
              const openInstallments = installments?.filter(
                (installment: LoanInstallment) =>
                  [
                    LoanInstallmentStatusEnum.PENDING_BATCH.id,
                    LoanInstallmentStatusEnum.PENDING_SEVERANCE_PAY.id,
                    LoanInstallmentStatusEnum.OPEN.id
                  ].includes(installment.statusId)
              );

              if (openInstallments && openInstallments.length) {
                const [
                  presentValueByInstallmentError,
                  presentValueByInstallment
                ] = await this.loanService.calculatePresentValue(
                  openInstallments.map(
                    (installment: LoanInstallment) => installment.id
                  )
                );

                if (!presentValueByInstallmentError) {
                  const updatedInstallments = openInstallments.map(
                    (installment: LoanInstallment) => {
                      const matchingPresentValue =
                        presentValueByInstallment!.find(
                          (pv) => pv.loanInstallmentId === installment.id
                        );
                      if (matchingPresentValue) {
                        return {
                          ...installment,
                          presentValue: matchingPresentValue.presentValue
                        };
                      } else {
                        return installment;
                      }
                    }
                  );
                  return {
                    ...loan,
                    debitValue:
                      this.sumOfDebitsValuesForLoans(updatedInstallments),
                    installments: updatedInstallments
                  };
                } else {
                  this.$notify({
                    type: "error",
                    text: getErrorMessageFromApiError(
                      presentValueByInstallmentError
                    )
                  });
                }
              }
            })
          )
        ).filter((loan) => loan);
      } else {
        this.borrowerLoans = borrowerLoans!.items;
      }
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingBorrowerLoans = false;
  }

  async fetchBorrowerLoanInstallments(): Promise<void> {
    if (this.form.payerTypeId === PayerTypeEnum.TOMADOR) {
      this.form.loanId = this.form.loans ? this.form.loans[0].id : null;
    }
    if (!this.form.loanId) return;

    this.borrowerLoanInstallments = [];
    this.form.loanInstallments = [];
    this.form.amount = 0;
    this.loadingBorrowerLoanInstallments = true;

    const [borrowerLoanInstallmentsError, borrowerLoanInstallments] =
      await this.loanService.getLoanInstallments(this.form.loanId);

    if (!borrowerLoanInstallmentsError) {
      const eligibleInstallments = borrowerLoanInstallments!.filter(
        (installment: LoanInstallment) =>
          [
            LoanInstallmentStatusEnum.OPEN.id,
            LoanInstallmentStatusEnum.PENDING_BATCH.id,
            LoanInstallmentStatusEnum.PENDING_SEVERANCE_PAY.id
          ].includes(installment.statusId)
      );

      if (eligibleInstallments.length) {
        const [presentValueByInstallmentError, presentValueByInstallment] =
          await this.loanService.calculatePresentValue(
            eligibleInstallments.map(
              (installment: LoanInstallment) => installment.id
            ),
            this.form.dueDate
              ? dayjs(this.form.dueDate).add(5, "day").format("YYYY-MM-DD")
              : dayjs().add(5, "day").format("YYYY-MM-DD")
          );

        if (!presentValueByInstallmentError) {
          this.borrowerLoanInstallments = eligibleInstallments.map(
            (installment: LoanInstallment) => {
              const { presentValue } = presentValueByInstallment!.find(
                (pv) => pv.loanInstallmentId === installment.id
              )!;

              const today = dayjs();
              const dueDate = dayjs(installment.dueDate);
              if (dueDate.isBefore(today, "day")) {
                installment.value = String(presentValue);
                installment.dueDateEdit = dayjs().format("YYYY-MM-DD");
              } else {
                installment.dueDateEdit = installment.dueDate;
              }

              return {
                ...installment,
                bankSlipValue: Number(installment.value),
                presentValue
              };
            }
          );
        } else {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(presentValueByInstallmentError)
          });
        }
      }
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(borrowerLoanInstallmentsError)
      });
    }
    this.loadingBorrowerLoanInstallments = false;
    this.form.loanInstallments = this.borrowerLoanInstallments;
  }

  sumOfSelectedLoanInstallments(): number {
    if (!this.form.loanInstallments) return 0;

    return this.form.loanInstallments.reduce(
      (acc: number, installment: LoanInstallment & { presentValue?: number }) =>
        acc + installment.presentValue!,
      0
    );
  }

  sumOfDebitsValuesForLoans(installments: LoanInstallment[]): number {
    return installments.reduce(
      (acc: number, installment: LoanInstallment & { presentValue?: number }) =>
        acc + installment.presentValue!,
      0
    );
  }

  @Watch("form.loans", { immediate: true, deep: true })
  onFormLoansChange(newVal, oldVal) {
    if (
      newVal !== oldVal &&
      this.form.payerTypeId === PayerTypeEnum.LIQUIDAR_CONTRATO
    ) {
      this.updateBorrowerInstallments(newVal);
      this.form.amount = this.sumOfSelectedLoanInstallments();
    }
  }

  @Watch("form.isUniqueBankSlip", { immediate: true, deep: true })
  onFormLoansChangeToUnique(newVal, oldVal) {
    if (newVal !== oldVal && this.form.payerTypeId === PayerTypeEnum.TOMADOR) {
      this.form.amount = this.sumOfSelectedLoanInstallments();
      this.form.dueDate = "";
    }
  }

  @Watch("form.loanInstallments", { immediate: true, deep: true })
  onFormLoansInstallmentsChange(newVal, oldVal) {
    if (newVal !== oldVal && this.form.payerTypeId === PayerTypeEnum.TOMADOR) {
      this.form.amount = this.sumOfSelectedLoanInstallments();
    }
  }

  validateDueDate(item) {
    const selectedDate = dayjs(item.dueDateEdit);
    const yesterday = dayjs().subtract(1, "day").endOf("date");

    if (selectedDate.isAfter(yesterday)) {
      item.dueDateEdit = selectedDate.toISOString().substr(0, 10);
      if (item.updateInstallment) {
        this.calculatePresentValue(item);
      }
    } else {
      item.dueDateEdit = null;
      this.form.loanInstallments = this.form.loanInstallments.filter(
        (itemToRemove) => item.id !== itemToRemove.id
      );
    }
  }

  async calculatePresentValue(item) {
    this.loading = true;
    const [presentValueByInstallmentError, presentValueByInstallment] =
      await this.loanService.calculatePresentValue(
        [item.id],
        dayjs(item.dueDateEdit).add(5, "day").format("YYYY-MM-DD")
      );

    if (!presentValueByInstallmentError) {
      item.bankSlipValue = presentValueByInstallment[0].presentValue;
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(presentValueByInstallmentError)
      });
    }
    this.loading = false;
  }

  @Watch("form.entityId")
  onChangePayer() {
    this.form.observation = "";
    this.form.loanInstallments = [];
    this.form.loans = [];
  }

  updateBorrowerInstallments(loans) {
    if (loans && loans.length > 0) {
      const allInstallments = [];
      for (const loan of loans) {
        allInstallments.push(...loan.installments);
      }
      this.form.loanInstallments = allInstallments;
    } else {
      this.form.loanInstallments = [];
    }
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  cpfValidation(value) {
    const cpf = value.replace(/\D/g, "");

    if (cpf.length !== 11) {
      return "CPF inválido";
    }

    if (/^(\d)\1+$/.test(cpf)) {
      return "CPF inválido";
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return "CPF inválido";
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return "CPF inválido";
    }

    // CPF válido
    return true;
  }

  close() {
    this.$emit("close");
  }
}
