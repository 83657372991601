
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import DatePicker from "@/components/DatePicker.vue";
import formatDatetime from "@/utils/formatDatetime";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format } from "@/utils/cpf";
import formatCurrency from "@/utils/formatCurrency";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import Company from "@/types/company";
import MarginBaseService, {
  MarginBaseRegisterCreateDTO,
  MarginBaseRegisterFilterList,
  MarginBaseRegisterList
} from "@/services/margin-base-service";
import MarginBaseRegister from "@/types/margin-base-register";
import Page from "@/components/core/dashboard/Page.vue";
import MarginBasesCreateModal from "@/components/margin-bases/MarginBasesCreateModal.vue";
import store from "@/store";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { MarginBaseTypeEnum } from "@/types/margin-base-type";

@Component({
  components: {
    Page,
    DatePicker,
    MarginBasesCreateModal
  }
})
export default class MaintenanceMarginBases extends Vue {
  companyService: CompanyService;
  selectedStatus: string | null = null;
  marginBaseService: MarginBaseService;
  selectedCompanies: Partial<Company>[] = [];
  marginId: number | null = null;
  companiesList: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  showEditModal: boolean = false;
  manualCreate: boolean = false;
  loading: boolean = false;
  loadCompanies: boolean = true;
  loadingExcel: boolean = false;
  filters: MarginBaseRegisterFilterList;
  headers: Array<DataTableHeader>;
  marginRegisters: MarginBaseRegisterList;
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  formatDatetime = formatDatetime;
  editMarginBaseData?: MarginBaseRegisterCreateDTO;
  marginTypes = MarginBaseTypeEnum;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    this.headers = [
      {
        text: "Data cadastro",
        value: "created_at",
        sortable: false
      },
      {
        text: "Empresa - CNPJ",
        value: "marginBase.cnpj",
        sortable: false
      },
      { text: "Colaborador CPF", value: "cpf", sortable: false },
      { text: "Data de Admissão", value: "admissionDate", sortable: false },
      {
        text: "Renda Liquida",
        value: "liquidIncome",
        sortable: false
      },
      {
        text: "Ocupação",
        value: "occupation",
        sortable: false
      },
      {
        text: "Status",
        value: "deleted_at",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false
      }
    ];
    this.filters = {
      page: 1,
      limit: 10,
      startedDate: null,
      endDate: null,
      companiesIds: null,
      marginIsActivated: null
    };
    this.marginRegisters = {
      items: [],
      total: 0
    };
  }

  created(): void {
    this.loadFilters();
  }

  async loadAllMargins(): Promise<void> {
    const [marginError, marginData] =
      await this.marginBaseService.listAllMarginBaseRegister({
        ...this.filters,
        companiesIds: this.filters.companiesIds
      });
    if (marginError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (marginData) {
      this.marginRegisters = marginData;
    }

    this.loading = false;
  }

  async loadFilters(): Promise<void> {
    const [companiesError, companiesData] =
      await this.companyService.listCompanies({
        page: 1,
        limit: -1,
        sort: "name:ASC",
        search: ""
      });
    if (companiesError) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao buscar os dados do relatório"
      });
    } else if (companiesData) {
      this.companiesList = companiesData;
      this.loadCompanies = false;
      if (!this.selectedCompanies.length && !this.isAdminGooroo) {
        this.filters.companiesIds = this.companiesList.items
          .map(({ id }) => id)
          .join(",");
      }
      if (this.companyId) {
        const selectedCompany = this.companiesList.items.find(
          (company) => company.id === this.companyId
        );
        if (selectedCompany) this.selectedCompanies.push(selectedCompany);
      }
    }
  }

  @Watch("selectedCompanies")
  updateCompanyFilters() {
    if (this.selectedCompanies && this.selectedCompanies.length > 0) {
      this.filters.companiesIds = this.selectedCompanies
        .map(({ id }) => id)
        .join(",");
    } else {
      this.filters.companiesIds = this.companiesList.items
        .map((company) => company.id)
        .join(",");
    }
  }

  async filterFromStart(): Promise<void> {
    this.filters.page = 1;
    this.loadAllMargins();
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.loadAllMargins();

    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ? sortBy[0].split(".")[1] : "id";
    const order = sortDesc[0] ? "DESC" : "ASC";

    return `${attr}:${order}`;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.startedDate = null;
    this.filters.endDate = null;
  }

  formatDate(date: string): string {
    if (!date) return "";
    const [data] = date.split("T");
    const [ano, mes, dia] = data.split("-");
    const dataFormatada = `${dia}-${mes}-${ano}`;
    return dataFormatada;
  }

  setManualCreateTrue(): void {
    this.manualCreate = true;
  }

  async downloadExcel(): Promise<void> {
    this.loadingExcel = true;
    const [error, data] =
      await this.marginBaseService.generateMarginBaseRegisterReportXls({
        ...this.filters,
        page: 1,
        limit: -1
      });
    if (!error) {
      window.open(
        process.env.VUE_APP_MARGIN_BASE_URL! +
          "/margin-base-register/report/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingExcel = false;
  }

  @Watch("editMarginBaseData")
  editMargin(data: MarginBaseRegister): void {
    this.editMarginBaseData = {
      companyId: data.marginBase?.companyId ?? null,
      registers: [
        {
          cpf: data.cpf ?? null,
          liquidIncome: data.liquidIncome,
          admissionDate: data.admissionDate ?? null,
          occupation: data.occupation ?? null
        }
      ]
    };
    this.showEditModal = true;
  }

  get companyId() {
    return store.getters["auth/currentUserCompanyId"];
  }

  @Watch("companyId")
  handleChangeCompany() {
    if (this.companyId) {
      this.selectedCompanies = [];
      const selectedCompany = this.companiesList.items.find(
        (company) => company.id === this.companyId
      );
      this.selectedCompanies.push(selectedCompany);
      if (this.selectedCompanies.length > 0) {
        this.filters.companiesIds = this.selectedCompanies
          .map((company) => company.id)
          .join(",");
        this.loadAllMargins();
      }
    }
  }

  updateStatusFilter() {
    switch (this.selectedStatus) {
      case "Ativo":
        this.filters.marginIsActivated = true;
        break;
      case "Desativado":
        this.filters.marginIsActivated = false;
        break;
      default:
        this.filters.marginIsActivated = null;
    }
  }
  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
